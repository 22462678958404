import React from 'react'
import '../../index.css'
import PricingPicture from '../../assets/pricing-img.png'

const pricing = () => {
  return (
    <section  style={{paddingBottom: 150}} className='sectionContainer'>
    <div className='mainContainer'>
      <div className="containerLeft">
      <h3 className='sectionTitle'>Kangahop<span className='highlight'> Pricing</span></h3>
      <div className="customLine-2">
        <span style={{marginBottom: 10}} className="topLine-2"></span>
        <span className="bottomLine-2"></span>
      </div>

      <p className='sectionParagraph'>Kangahop is dedicated to providing safe, reliable, and convenient transportation solutions for children. Founded by parents, for parents, our mission is to simplify daily school commutes and give families peace of mind. </p>
      <p className='sectionParagraph'> We envisioned a solution that would not only ease our daily routines but also ensure the utmost safety for our kids. That's how KangaHop came to life.</p>
      </div>
      <div className="containerRight pricingImageSec">
        <img src={PricingPicture} alt="pricing" />

      </div>
    </div>
 </section>
  )
}

export default pricing
