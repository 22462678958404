import React from 'react'
import FeaturesTreeImage from '../../assets/features-tree-img.png'
import FeaturesPng from '../../assets/all-features-img.png'
import './features.css'

const features = () => {

    // Function to handle right-click on the image
    const handleContextMenu = (event) => {
      event.preventDefault();
    }


  return (
    <section className='featuresSection'>
      <div className="featuresContainer">
      <h3 style={{textAlign: 'center', marginBottom: 50}} className='sectionTitle'>Feat<span className='highlight'>ures</span></h3>
      <div className="customLines">
              <span className="topLine"></span>
              <span className="bottomLine"></span>
            </div>
      <div className="featuresTree" >
        <div onContextMenu={handleContextMenu}>
        <img className='mainFeaturesImg' src={FeaturesTreeImage} alt=" Features"  />
        <img className='FeaturesmobileImage' src={FeaturesPng} alt='features 2' />
        </div>
        
        <div className="treeContent">
          <div className="treeLeft">
            <div className="treeItem">
              <h3 className="treeTitle title-one-left">Safety First</h3>
              <p className="treeDescription desc-one-left">Our top priority is the safety of your child. All our childminders are thoroughly vetted
              and background-checked.</p>
            </div>
            <div className="treeItem">
              <h3 className="treeTitle title-two-left">Track and relax</h3>
              <p className="treeDescription desc-two-left">Real-Time Tracking and video monitoring: Track and view your child&#39;s journey in
              real-time with our easy-to-use app. From pick up to drop off</p>
            </div>
          </div>
          {/* ======Right tree section========= */}
          <div className="treeRight">
            <div className="treeItem">
              <h3 className="treeTitle title-three-right">Convenient Booking</h3>
              <p className="treeDescription desc-three-right">Book rides for school runs, after-school activities, or weekend events.
              Schedule rides with just a few taps. Manage your child's transportation effortlessly.</p>
            </div>
            <div className="treeItem">
              <h3 className="treeTitle title-four-right">Vetted Childminders</h3>
              <p className="treeDescription desc-four-right ">All our childminders are qualified, experienced, and background-checked.
              Also, other parents review available on the app.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  )
}

export default features
