import React, { useRef, useState, forwardRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';

const Contact = forwardRef((props, ref) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phone) => {
    const re = /^[0-9]*$/;
    return re.test(String(phone));
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (email.trim() === '' || validateEmail(email)) {
      setEmailError('');
    } else {
      setEmailError('Enter a valid email');
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (phone.trim() === '' || validatePhoneNumber(phone)) {
      setPhoneError('');
    } else {
      setPhoneError('Enter a valid phone number');
    }
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input for phone number
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Validate fields before sending email
    if (!validateEmail(form.current.user_email.value)) {
      setEmailError('Enter a valid email');
      return;
    } else {
      setEmailError('');
    }

    if (form.current.message.value && !validatePhoneNumber(form.current.message.value)) {
      setPhoneError('Enter a valid phone number');
      return;
    } else {
      setPhoneError('');
    }

    setLoading(true);

    emailjs
      .sendForm('service_nv0sv48', 'template_t0x4d7p', form.current, {
        publicKey: 'XVJoG85elQ4aEiuj_',
      })
      .then(
        () => {
          setLoading(false);
          toast.success('Email sent successfully!');
          form.current.reset();  // Clear the form
        },
        (error) => {
          setLoading(false);
          toast.error('Failed to send email. Please try again.');
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <section className="contactSection" id='contact' ref={ref}>
      <div className="contactContainer">
        <h3 style={{ textAlign: 'center', marginBottom: 50 }} className='sectionTitle'>
          Contact <span className='highlight'>Us</span>
        </h3>
        <div className="customLines">
          <span className="topLine"></span>
          <span className="bottomLine"></span>
        </div>
        <form className="contactForm" ref={form} onSubmit={sendEmail}>
          <div className="formGroup">
            <input
              type="text"
              id="name"
              name="from_name"
              placeholder='Enter your full name'
              required
            />
          </div>
          <div className="formGroup">
            <input
              type="email"
              id="email"
              name="user_email"
              placeholder='Enter your email'
              required
              className={emailError ? 'error' : ''}
              onChange={handleEmailChange}
            />
            {emailError && <span className="errorText">{emailError}</span>}
          </div>
          <div className="formGroup">
            <input
              type="text"
              id="phonenumber"
              name="message"
              placeholder='Enter your phone number (optional)'
              className={phoneError ? 'error' : ''}
              onChange={handlePhoneChange}
              onKeyPress={handleKeyPress}
            />
            {phoneError && <span className="errorText">{phoneError}</span>}
          </div>
          <div className="formGroup">
            <textarea id="message" name="message" rows="5" required placeholder='Enter your message here...'></textarea>
          </div>
          <div className='btnContainer'>
            <button className='ContactBtn' type="submit" disabled={loading}>
              {loading ? 'Sending...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
});

export default Contact;
