import React from 'react'
import '../../index.css'
import './mission.css'
import TeamPicture from '../../assets/svg/team-spirit.svg'


const Mission = () => {
  return (
    <section  className='sectionContainer'>
      <div className='mainContainer'>
        <div className="containerRight">
        <h3 style={{marginTop: 40}} className='sectionTitle' >Company Vision and <span className='highlight'>Mission</span></h3>
        <div className="customLine-2">
        <span style={{marginBottom: 10}} className="topLine-2"></span>
        <span className="bottomLine-2"></span>
      </div>

        <div style={{marginTop: 40}} className="sectionContent">
        <h3 className='highlight' >Our Mission </h3>
        <p className='sectionParagraph'> &quot; At Kangahop, we aim to revolutionize child transportation by providing safe, reliable,
        and convenient solutions for busy parents.&quot;</p>
        </div>
        <div style={{marginTop: 40}} className="sectionContent">
        <h3 className='highlight' >Our Story</h3>
        <p className='sectionParagraph'>Founded by parents, for parents, we understand the challenges of managing school
        runs and have designed Kangahop to address these needs.</p>
        </div>
        </div>
        <div className="containerLeft aboutImgSec">
        <img src={TeamPicture} alt="about" />
    
        </div>
      </div>
   </section>
  )
}

export default Mission
