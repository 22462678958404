import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import './faq.css'

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "When will kangahop be launched?",
      answer: "KangaHop will be officially launched in August. Stay tuned for updates and be among the first to experience our safe and reliable ride services for your children."
    },
    {
      question: "How does KangaHop ensure safety?",
      answer: "We have a rigorous vetting process for drivers, use real-time tracking, and provide updates to parents to ensure the utmost safety."
    },
    {
      question: "How can I book a ride?",
      answer: "You can book a ride through our mobile app available on both Android and iOS platforms."
    },
    {
      question: "What areas do you service?",
      answer: "We currently service various metropolitan areas. Please check our website for the latest service locations."
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className='sectionContainer'>
      <h3 style={{ textAlign: 'center', marginBottom: 50  }} className='sectionTitle'>Frequently Asked<span className='highlight'> Questions</span></h3>
      <div className="customLines">
              <span className="topLine"></span>
              <span className="bottomLine"></span>
            </div>
      <div style={{marginTop: 20}} className="faqContainer">
        {faqs.map((faq, index) => (
          <div key={index} className="faqItem">
            <div className="faqQuestion" onClick={() => toggleAccordion(index)}>
              {faq.question}
              <span className="caret">
                <FontAwesomeIcon icon={activeIndex === index ? faChevronUp : faChevronDown} />
              </span>
            </div>
            <div className={`faqAnswer ${activeIndex === index ? 'active' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Faq
