import React from 'react'
import './footer.css'
import Logo from '../../assets/FooterLogo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const footer = () => {
  
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className='SectionContainer'>
      <footer className='footerSection'>
        {/* ====row 1====== */}
      <div className='footerLogo'>
        <img src={Logo} alt="Footer Logo" />
      </div>
      {/* ===row 2==== */}
      <div className='UsefulLinks'>
        <h3 className='footerTitle'>Useful Links</h3>
     
        <ul className='linkList'>
          <li className='linkName'><a href="#about">About</a></li>
          <li className='linkName'><a href="#faq">Faq</a></li>
          <li className='linkName'><a href="#pricing">Pricing</a></li>
          <li className='linkName'><a href="#contact">Contact</a></li>
        </ul>
      </div>
      {/* =======row 3========= */}
    <div className='contactColumn'>
      <h3 className='footerTitle'>Contact</h3>
      <div>
      <p className='item'> <b>Number:</b> < a href="tel:+447852215252">+44 7852 215 252</a></p>
      <p style={{marginTop: 10}} className='item'><b>Email:</b> <a href="mailto:hello@kangahop.io ">hello@kangahop.io </a></p>
      </div>
      <h3 style={{marginTop: 10}} className='footerTitle'>Socials</h3>
      <div className="socialIcons">
            <a href="https://www.facebook.com/profile.php?id=61560979865383&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://x.com/KangahopApp?t=GPwPRYw9pWanyKYRLM0JJw&s=09" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.linkedin.com/company/kangahop/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.instagram.com/kangahop.io?igsh=MWEycmp4OWo3dWI0cA==" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
    </div>
      {/* =======row 4========= */}
    <div className='FooterwaitList'>
      <h3 className='footerTitle'>Join our waitlist</h3>
      <div>
      <p className='footersubtitle'>Be the first to know after we launch our mobile app</p>
      <div className='FooteremailInput'>
        <form >
          {/* <input type="email" placeholder="Enter your email" /> */}
          <button onClick={scrollToContact} type="button">Join Waitlist</button>
        </form>
        </div>
      </div>
    </div>
      </footer>
      <div className='FooterCopyright'>
     <p className='FooterCopyrighttext'>Copyright 2024 || All rights reserved || KangaHop</p>
      </div>
    </section>
  
  )
}

export default footer