import React from 'react'
import './header.css'
import '../../index.css'
import Logo from '../../assets/FooterLogo.svg'
import RightHeaderIllustration from '../../assets/header-img.png'
import StoreBtn from '../../assets/download-store.png'

const header = () => {

  // const scrollToContact = () => {
  //   const contactSection = document.getElementById('contact');
  //   if (contactSection) {
  //     contactSection.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    console.log('Contact Section:', contactSection); // Debugging log
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
      console.log('Scrolling to contact section'); // Debugging log
    } else {
      console.error('Contact section not found'); // Error log
    }
  };

  return (
   <section className='sectionContainer headerContainer'>
      <div className="headerLogo">
        <img src={Logo} alt="header logo" />
      </div>
      <div className='mainContainer homemainContent' >
        <div className="containerLeft headerleftcontent">
         <div className='commingSoon'>
          <h5><span className='highlight'>coming </span>soon...</h5>
         </div>
        <div className='Leftcontent'>
          <h3>Safe and <span className="highlight">Reliable</span> Transportation At Your Finger Tips</h3>
          <p>Simplifying school runs and giving parents peace of mind with real-time tracking and vetted
          childminders.</p>
        <div className='emailInput'>
        <form>
          {/* <input type="email" placeholder="Enter your email" /> */}
          <button type="button" onClick={scrollToContact}>Join Waitlist</button>
        </form>
        </div>
        <div className="stores">
          <img src={StoreBtn} alt="stores" />
        </div>
        </div>
        </div>
        <div className="containerRight headerRight">
          <img className='HeaderImage' src={RightHeaderIllustration} alt="  Heading" />
        </div>
      </div>
   </section>
  )
}

export default header
