import React from 'react';
import './Status.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const Failed = () => {
  return (
    <div className="success-container">
      <div className="success-content">
      <div className="checkmark-circle">
          <FontAwesomeIcon icon={faCheckCircle} className="checkmark-failed" />
        </div>
        <h1 className="success-title">Transaction Failed!</h1>
        <p className="success-message">
          Your transaction failed! Please try again later.
        </p>
        <button className="status-button" onClick={() => window.location.href = '/'}>
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default Failed;
