import React from 'react';
import './Status.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const Success = () => {
  return (
    <div className="success-container">
    <div className="success-content">
      <div>
        <FontAwesomeIcon icon={faCheckCircle} className="checkmark-success" />
      </div>
      <h1 className="success-title">Transaction Successful!</h1>
      <p className="success-message">
        Thank you! Your transaction has been completed successfully.
      </p>
      <button className="status-button" onClick={() => window.location.href = '/'}>
        Go to Home
      </button>
    </div>
  </div>
  );
}

export default Success;
