import React from 'react'
import Header from '../../components/header/header'
import About from '../../components/about/about'
import Footer from '../../components/footer/footer'
import Faq from '../../components/faq/faq'
import Contact from '../../components/contact/contact'
import Features from '../../components/features/features'
import Pricing from '../../components/pricing/pricing'
import Mission from '../../components/mission/mission'

const LandingPage = () => {
  return (
    <div>
        <Header />
        <About />
        <Mission/>
        <Features/>
        <Pricing/>
        <Faq/>
        <Contact />
        <Footer />
    </div>
  )
}

export default LandingPage