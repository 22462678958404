import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingPage from "./pages/landing-page/LandingPage";
// transaction redirection
import Success from "./pages/transaction-status/Success";
import Failed from "./pages/transaction-status/Failed";
import Canceled from "./pages/transaction-status/Canceled";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Landing page route */}
        <Route path="/" element={<LandingPage />} />
        
        {/* Transaction status routes */}
        <Route path="/success" element={<Success />} />
        <Route path="/failed" element={<Failed />} />
        <Route path="/cancel" element={<Canceled />} />
      </Routes>
    </Router>
  );
};

export default App;
