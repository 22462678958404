import React from 'react'
import '../../index.css'
import './about.css'
import AboutImage from '../../assets/about-img.png'


const about = () => {
  return (
    <section className='sectionContainer'>
      <div className='mainContainer'>
        <div className="containerLeft aboutImgSec">
        <img src={AboutImage} alt="about" />
    
        </div>
        <div className="containerRight">
        <h3 style={{marginTop: 40}} className='sectionTitle' >About <span className='highlight'>Us</span></h3>
        <div className="customLine-2">
        <span style={{marginBottom: 10}} className="topLine-2"></span>
        <span className="bottomLine-2"></span>
      </div>

        <div className="sectionContent">
        <p className='sectionParagraph'>Kangahop is dedicated to providing safe, reliable, and convenient transportation solutions for children. Founded by parents, for parents, our mission is to simplify daily school commutes and give families peace of mind. </p>
        <p className='sectionParagraph'> We envisioned a solution that would not only ease our daily routines but also ensure the utmost safety for our kids. That's how KangaHop came to life.</p>
        </div>
        </div>
      </div>
   </section>
  )
}

export default about
